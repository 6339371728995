import React from 'react';
import { Layout as Root, SectionContainer } from '../../components';

import Styles from './Styles';

const Layout = ({ children, metadata }) => {
    return (
        <Root {...metadata}>
            <Styles>
                <SectionContainer>{children}</SectionContainer>
            </Styles>
        </Root>
    );
};

export default Layout;
