import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/layouts/Default/index.js";
import FeatureSubPages from '../components/featureSubPages/index.jsx';
export const metadata = {
  title: 'Features'
};
export const _frontmatter = {};
const layoutProps = {
  metadata,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <FeatureSubPages mdxType="FeatureSubPages" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      