import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { Text, ButtonStyle } from '../../components';
import styled, { createGlobalStyle } from 'styled-components';
import { darken, transition } from '../../utils/mixins';
import { Heading, Paragraph } from '@cheapreats/react-ui';

// TODO: Andrew image component
const components = {
    h1: (props) => (
        <Heading type="h1" margin="0.25rem auto" color="primary" {...props} />
    ),
    h2: (props) => (
        <Heading type="h2" margin="0.25rem auto" color="primary" {...props} />
    ),
    h3: (props) => (
        <Heading
            color="primary"
            margin="0.25rem auto"
            color="black"
            type="h3"
            {...props}
        />
    ),
    p: (props) => <Paragraph margin="0.25rem auto" color="black" {...props} />,
};

export default ({ children }) => (
    <MDXProvider components={components}>
        <GlobalStyles />
        {children}
    </MDXProvider>
);

const GlobalStyles = createGlobalStyle`
    ${({ theme }) => `
    main {
        a:not(${ButtonStyle}) {
            ${transition(['color'])}
            color: ${theme.colors.primary};
            &:hover {
                color: ${darken(theme.colors.primary)};
            }

            &:active {
                color: ${darken(theme.colors.primary, 2)};
            }
        }

        h2 + h3 {
            margin-top: ${theme.spacing.component} !important;
        }
    }
    `}
`;

const StyledText = styled(Text)`
    margin-top: ${({ theme, spacing = 'section' }) =>
        theme.spacing[spacing] || spacing};
`;

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
`;

export const CardWrapperLarge = styled(CardWrapper)`
    flex-direction: column;
    max-width: 505px;
`;
